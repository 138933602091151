import ApiService from './ApiService'

export async function apiGetSystem(data) {
    return ApiService.fetchData({
        url: '/system/1',
        method: 'get',
        data,
    })
}

export async function apiEditSystem(data) {
    return ApiService.fetchData({
        url: '/system/1',
        method: 'patch',
        data,
    })
}

export async function apiDownloadSysImg(data) {
    return ApiService.fetchData({
        url: `/system/downloadImage/1`,
        method: 'get',
        data,
    })
}

export async function apiUploadSysImg(image_type, data) {
    return ApiService.fetchData({
        url: `/system/uploadImage/1/${image_type}`,
        method: 'patch',
        data,
    })
}
