import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    sysLogo: '',
    currentRouteKey: '',
    errorDialog: {
        isOpen: false,
    },
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setSystemLogo: (state, action) => {
            state.sysLogo = action.payload
        },
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setErrorStatus: (state, action) => {
            state.errorDialog = action.payload
        },
    },
})

export const { setSystemLogo, setCurrentRouteKey, setErrorStatus } =
    commonSlice.actions

export default commonSlice.reducer
