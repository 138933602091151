import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
    const roleMatched = useMemo(() => {
        // filter all the module from user
        const permission = userAuthority.filter(
            (module) => authority.includes(module.module_id)
        ).map(
            (module) => {
                // if 'read', than get read_access
                if (authority[0] === 'read') {
                    return module.read_access || false
                // if 'write', than get write_access
                }else if (authority[0] === 'write') {
                    return (module.read_access && module.write_access) || false
                }
                return false
            }
        )
        return permission.length !== 0 ?
            // if every acess is true, than return true, otherwise false
            permission.every((access) => access === true) :
            false
    }, [authority, userAuthority])

    if (
        isEmpty(authority) ||
        isEmpty(userAuthority) ||
        typeof authority === 'undefined'
    ) {
        if (isEmpty(authority)) {
            return !emptyCheck
        }
        if (isEmpty(userAuthority)) {
            return emptyCheck
        }
        return !emptyCheck
    }
    return roleMatched
}

export default useAuthority
