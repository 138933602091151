import React from 'react'
import { TbChevronsLeft, TbChevronsRight } from 'react-icons/tb'

const NavToggle = ({ toggled, className }) => {
    return (
        <div className={className}>
            {toggled ? <TbChevronsRight /> : <TbChevronsLeft />}
        </div>
    )
}

export default NavToggle
