import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/auth/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/signup',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/auth/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/auth/forgot_password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data, token) {
    return ApiService.fetchData({
        url: '/auth/reset_password',
        method: 'post',
        data,
        token,
    })
}
